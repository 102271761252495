<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="employeeAccounts.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('DeployProject')"
    @cancel="onCancel('DeployProject')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'DeployProjectCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'DeployProject',
      pageHeader: 'Создание публикации приложения',
      breadcrumbs: [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Публикуемые проекты',
          route: { name: 'DeployProject' },
        },
        {
          text: 'Создание публикации приложения',
        },
      ],
      initialForm: {
        name: null,
        permissions: [],
        publications: [],
        structureRequirements: [],
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      employeeAccounts: (state) => state.common.employeeAccounts,
    }),

    permissionList() {
      if (this.form?.permissions === undefined) return [];

      return this.form.permissions.map((item) => ({
        ...item,
        name: this.employeeAccounts.data.find(
          (element) => element.id === item.accountId
        ).name,
      }));
    },

    structureRequirementList() {
      if (this.form?.structureRequirements === undefined) return [];

      return this.form.structureRequirements.map((item) => ({
        ...item,
        isFile: item.isFile ? 'Файл' : 'Каталог',
        shouldNotExist: item.shouldNotExist
          ? 'Не должен существовать'
          : 'Должен существовать',
      }));
    },

    fields() {
      return [
        {
          tab: 'Наименование',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
          ],
        },
        {
          tab: 'Разрешения на добавление релизов',
          table: {
            caption: 'Разрешения на добавление релизов',
            headers: [
              {
                text: 'Пользователь, которому разрешено добавление релиза',
                alias: 'name',
              },
              { alias: 'actions' },
            ],
            items: this.permissionList,
            key: 'permissions',
            modalFields: [
              {
                type: 'select',
                key: 'accountId',
                label: 'Пользователь, которому разрешено добавление релиза',
                list: this.employeeAccounts.data,
              },
            ],
            showEditButton: true,
            showDeleteButton: true,
            showCreateButton: true,
          },
        },
        {
          tab: 'Публикации проекта',
          table: {
            caption: 'Публикации проекта',
            headers: [
              {
                text: 'Название',
                alias: 'name',
              },
              {
                text: 'Код доступа',
                alias: 'accessKey',
              },
              {
                text: 'Пароль для доступа',
                alias: 'accessPassword',
              },
              { alias: 'actions' },
            ],
            items: this.form.publications,
            key: 'publications',
            modalFields: [
              {
                type: 'text',
                key: 'name',
                label: 'Название',
              },
            ],
            showEditButton: true,
            showDeleteButton: true,
            showCreateButton: true,
          },
        },
        {
          tab: 'Требования к файлу релиза',
          table: {
            caption: 'Требования к файлу релиза',
            headers: [
              {
                text: 'Путь в архиве. Используется разделитель /',
                alias: 'path',
              },
              {
                text: 'Файл / каталог',
                alias: 'isFile',
              },
              {
                text: 'Не должен существовать / должен существовать',
                alias: 'shouldNotExist',
              },
              { alias: 'actions' },
            ],
            items: this.structureRequirementList,
            key: 'structureRequirements',
            modalFields: [
              {
                type: 'text',
                key: 'path',
                label: 'Путь в архиве. Используется разделитель /',
              },
              {
                type: 'checkbox',
                key: 'isFile',
                label: 'Файл (если выбран) / каталог (если не выбран)',
              },
              {
                type: 'checkbox',
                key: 'shouldNotExist',
                label:
                  'Не должен существовать (если выбран) / должен существовать (если не выбран)',
              },
            ],
            showEditButton: true,
            showDeleteButton: true,
            showCreateButton: true,
          },
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'EmployeeAccounts' });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      createItem: 'editableList/createItem',
    }),
  },
};
</script>
